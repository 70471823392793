<template>
  <div>
     <b-modal
      id="bill-to-receive-product-modal"
      hide-header
      hide-footer
      centered
      size="lg"
      @hidden="onHidden"
      @show="onShow"
    >
      <div class="modal-header">
        <div class="modal-title">Conta a receber</div>
        <span class="icon-box"><v-close class="icon stroke" @click="closeModal"  /></span>
      </div>

      <div class="md-content">
        <div class="row">
          <div class="col-4">
            <b-form-group>
              <label for="field_date" id="title-styles">Data do atendimento</label>
              <date-picker
                class="full"
                v-model="form.service_date"
                format="DD/MM/YYYY"
                placeholder="DD/MM/AAAA"
                :clearable="false"
                :lang="langDatePicker"
                :invalid-feedback="'Campo Obrigatório'"
              />
            </b-form-group>
          </div>
          <div class="col-8">
            <v-patient-input
              required
              disabled
              v-model="form.patient"
              :error="validated && !form.patient"
            />
          </div>

          <b-table-simple class="table">
            <thead>
              <tr>
                <td class="background head">Cód. de barras</td>
                <td class="background head">Lote</td>
                <td class="background head">Nome</td>
                <td class="background head">Qtde.</td>
                <td class="background head">Custo unitário</td>
                <td class="background head">Venda unitário</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(transaction, index) in form.transactions.filter(t => t.checked)" :key="index">
                <td class="dashed">{{ transaction?.barcode }}</td>
                <td class="dashed">{{ transaction.batch }}</td>
                <td :class="{ product: true,  dashed: index !== 0 }">{{ transaction.product.name }}</td>
                <td class="dashed">{{ transaction.quantity }}</td>
                <td class="dashed">{{ parseNumberToMoney(transaction.purchase_price) }}</td>
                <td class="dashed">{{ parseNumberToMoney(transaction.sale_price) }}</td>
              </tr>
              <tr>
                <td class="background head" colspan="5">Valor total de custo</td>
                <td class="background total">{{ parseNumberToMoney(this.getTotalAmount()) }}</td>
              </tr>
              <tr>
                <td class="background head" colspan="5">Valor total de venda</td>
                <td class="background total">{{ parseNumberToMoney(this.getTotalAmountSale()) }}</td>
              </tr>
            </tbody>
          </b-table-simple>

          <div class="col">
            <b-form-group>
              <label for="field_amount" id="title-styles">Valor total</label>
              <money
                class="form-control"
                :value="form.amount"
                @input="handleAmount"
                disabled
              />
              <div v-if="validated && !form.amount" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-form-group>
          </div>

          <div class="col" v-if="form.creditDiscount > 0">
            <b-form-group>
              <label for="field_credit_discount" id="title-styles">Desconto crédito</label>
              <money
                class="form-control"
                disabled
                v-model.lazy="form.creditDiscount"
              />
            </b-form-group>
          </div>

          <div class="col">
            <b-form-group>
              <label for="field_discount" id="title-styles">Desconto</label>
              <span class="help" id="help">(opcional)</span>
              <money
                v-if="form.currency.value === 'R$'"
                class="form-control"
                :value="form.discount"
                @input="handleDiscount"
              />  
              <b-form-input
                v-else
                autocomplete="off"
                class="form-control"
                v-model="form.discount"
              />
            </b-form-group>
          </div>

          <div class="col">
            <b-form-group>
              <label for="currency" id="title-styles">Tipo</label>
              <span v-if="!form.discount" class="help" id="help">(opcional)</span>
              <multiselect
                track-by="value"
                label="label"
                placeholder="Selecionar"
                :options="currencies"
                :allow-empty="false"
                :option-height="40"
                :showLabels="false"
                :showNoResults="false"
                class="with-border"
                v-model="form.currency"
              >
                <template slot="caret">
                  <div class="chevron">
                    <v-chevron-down />
                  </div>
                </template>
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.label }}
                </template>

                <template slot="noOptions">
                  Nenhuma opção
                </template>

                <template slot="noResult">
                  Nenhum resultado
                </template>
              </multiselect>
              <div v-if="validated && form.discount && !form.currency" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-form-group>
          </div>

          <div class="col">
            <b-form-group>
              <label for="field_final_amount" id="title-styles">Valor final</label>
              <money
                class="form-control"
                :value="form.final_amount"
                disabled
              />
              <div v-if="validated && !form.final_amount" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-form-group>
          </div>

            <div class="col-12" v-if="!!canUsePatientCredit">
							<div class="d-flex justify-content-between align-items-center border rounded px-3 py-1">

								<div class="d-flex flex-row align-items-center justify-content-center m-0">
									<b-form-checkbox
									id="use_patient_credit"
									v-model="usePatientCredit"
									switch
									class="use-patient-credit"
                  :disabled="!patientCredit?.credit > 0"
									>
									<span class="label">Crédito do paciente</span>
									</b-form-checkbox>
								
								</div>
								<div class="credit-available">
									Crédito atual {{ creditFormatted }}
								</div>
							</div>
						</div>

						<div class="col-12 row py-1" v-if="usePatientCredit && canUsePatientCredit ">
							<b-form-group class="col-6">
								<label for="credit_amount" class="d-flex flex-row justify-content-between align-itens-center">
                  Valor a ser utilizado
                  <b-form-checkbox
                    id="use_credit_total"
                    v-model="useCreditTotal"
                    class="use-credit-total"
                    :disabled="!usePatientCredit"
                  >
                    <span class="use-credit__label">
                      Utilizar crédito total
                    </span>
                  </b-form-checkbox>
                </label>
								<money
									class="form-control"
									v-model="creditAmount"
									:disabled="!usePatientCredit"
                  @onChange="handleChangeCreditAmount"
								/>
							</b-form-group>
							<b-form-group class="col-6">
								<label for="balance">Saldo</label>
								<money
									class="form-control"
									:value="balanceValue"
									:disabled="true"
								/>
                <div v-if="validated && isCreditBalanceNegative" class="custom-invalid-feedback">
                  O saldo não pode ficar negativo
                </div>
							</b-form-group>
						</div>
  
        </div>

        <Payments
          ref="payments"
          :useTax="this.creditPatientCategory?.name === 'Depósito de crédito (paciente)'"
          :validated="validated"
          :bill="form"
          :totalAmount="form.final_amount"
          :isCreditCategory="isPatientCreditCategory"
        />

        <div class="action-button-wrapper">
          <b-button
            class="action-button"
            variant="link"
            size="lg"
            @click="closeModal()"
          >
              Voltar a saída
          </b-button>
          <b-button
            class="action-button"
            variant="primary"
            size="lg"
            @click="createBillToReceive"
            clicked="true"
          >
              Salvar conta a receber e efetuar a saída
          </b-button>
        </div>
        <ConfirmEntryModal
          identifier="confirm-close-entry-modal"
          title="Aviso"
          body="Atenção! Existem atualizações nesta saída não concluídas."
          description="Deseja realmente sair sem salvar?"
          cancelButtonText="Voltar"
          confirmButtonText="Sair sem salvar"
          :confirmed="confirmedClose"
        />
      </div>

     </b-modal>
  </div>
</template>
<script>
import financialApi from '@/modules/financial/api'
import { track } from '@/utils/mixPanel'
import { parseNumberToMoney } from '@/utils/moneyHelper';
import Close from '@/assets/icons/close.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import PatientInput from '@/components/General/PatientInput'
import { showFeature, showInvoicingTiss, hasPermissionPatientCreditCategory } from '@/utils/permissionsHelp'
import { EventBus } from '@/utils/eventBus'

export default {
  components: {
    'v-close': Close,
    'v-chevron-down': ChevronDown,
    'v-patient-input': PatientInput,
    Payments: () => import('@/modules/financial/components/Payments'),
    ConfirmEntryModal: () => import('@/components/Warehouse/ConfirmEntryModal.vue'),
  },
  props: {
    billToReceive: Object,
  },
  data() {
    return {
      clicked: "false",
      clinic: JSON.parse(localStorage.getItem('clinic')),
      validated: false,
      form: this.getDefaultForm(),
      currencies: [{ label: 'R$', value: 'R$' }, { label: '%', value: '%' }],
      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      },
      patientCreditCategory: null,
      patientCredit: null,
      usePatientCredit: false,
      creditAmount: 0,
      creditPatientCategory: null,
      useCreditTotal: false,
      patientId: null,
      outputItem: null,
    }
  },
  computed: {
    canUsePatientCredit() {
      return this.creditPatientCategory?.name && this.canLoadCreditPatient
    },
    isPatientCreditCategory() {
      return this.creditPatientCategory && this.creditPatientCategory.name === 'Depósito de crédito (paciente)'
    },
    creditFormatted() {
      return `R$${parseNumberToMoney(this.patientCredit?.credit).trim().split('$')[1] }`
    },
    balanceValue() {
      return this.patientCredit?.credit - this.creditAmount || 0
    }, 
    canLoadCreditPatient() {
      return this.showFeature('Caução do paciente') && this.hasPermissionPatientCreditCategory()
    },
    isCreditBalanceNegative() {
      return this.balanceValue < 0
    }
  },
  methods: {
    hasPermissionPatientCreditCategory,
    showFeature,
    showInvoicingTiss,
    parseNumberToMoney,
    async onShow() {
      if (this.canLoadCreditPatient && !this.isPatientCreditCategory) {
          await this.getPatientDepositCategory()
        }
    },
    goPatient() {
        const route = this.$router.resolve(`/pacientes/${this.form.patient.id}/account`)
      window.open(route.href, '_blank');
      },
    handleAmount(value) {
      if (!value) return
      this.form.amount = value
    },
    handleDiscount(value) {
      if (!value) return
      this.form.discount = value
    },
    onHidden() {
      this.clearForm();
      this.validated = false;
      this.form = this.getDefaultForm();
      this.$emit('onHide');
    },
    closeModal() {
      this.$bvModal.show('confirm-close-entry-modal')
    },
    confirmedClose(confirmed) {
      if (confirmed) {
        this.clearForm()
        this.$emit('onHide')
        this.$bvModal.hide('bill-to-receive-product-modal')
      }
      this.$bvModal.hide('confirm-close-entry-modal')
    },
    getDefaultForm() {
      return {
        id: null,
        type: 'bill_to_receive',
        category: null,
        patient: null,
        person: null,
        description: null,
        appointment_id: null,
        health_plan: null,
        plan: null,
        service_date: new Date(),
        amount: 0,
        discount: 0,
        currency: { label: 'R$', value: 'R$' },
        final_amount: 0,
        payments: [],
        transactions: [],
        creditAmount: 0,
        creditPatientCategory: null,
        patientCredit: null,
        creditDiscount: 0,
      };
    },
    getTotalAmountSale() {
      return this.form.transactions
        .filter(t => t.checked)
        .reduce((acc, p) => acc + (p.quantity * p.sale_price), 0);
    },
    getTotalAmount() {
      return this.form.transactions
        .filter(t => t.checked)
        .reduce((acc, p) => acc + (p.quantity * p.purchase_price), 0);
    },
    getProductCategory() {
      return new Promise((resolve, reject) => {
        this.api.getProductCategory(this.clinic.id)
        .then(res => resolve(res.data))
        .catch(reject);
      });
    },
     parseFormData() {
      return {
        type: this.form.type,
        clinic_id: this.clinic.id,
        category_id: this.form.category.id,
        patient_id: this.form.patient.id,
        person_id: this.form.person ? this.form.person.id : null,
        description: this.form.description,
        appointment_id: this.form.appointment ? this.form.appointment.id : null,
        service_date: this.moment(this.form.service_date).format('YYYY-MM-DD'),
        amount: this.getAmount(),
        discount: this.getDiscount(),
        currency: this.getCurrencyDiscount(),
        final_amount: this.getFinalAmount(),
        payments: this.form.payments.map(payment => ({
          id: payment.id,
          amount: payment.amount,
          due_date: payment.due_date,
          bank_account_id: payment.bank_account.id,
          payment_method_id: payment.payment_method.id,
          installments: payment.installments
        })),
        billProducts: this.form.transactions.filter(t => t.checked).map(t => ({
          product_id: t.product.id,
          quantity: t.quantity,
          amount: t.sale_price,
        })),
        creditPatientAmount: this.creditAmount > 0 ? this.creditAmount : null,
        creditPatientCategory: this.creditPatientCategory?.id
      };
    },
    async createBillToReceive() {
      if (this.clicked === "true") return;
      this.clicked = "true";
      if (!this.isValid()) return;
      const data = this.parseFormData();
      const isLoading = this.$loading.show();
      try {
        await this.api.createWareHouseOutput(this.outputItem);
        isLoading.hide();
        EventBus.$emit('reloadProductOutputs');
        EventBus.$emit('reloadProducts');
        EventBus.$emit('reloadBatches');
        this.$toast.success('Saída realizada com sucesso!');

        await this.api.createBill(data);
        isLoading.hide();
        this.$toast.success('Conta a receber cadastrada com sucesso!');


        track('conta_a_pagar_entrada_estoque', {
          clinic: this.clinic.name,
          total: data.final_amount,
        });

        this.form.payments.map(payment => {
          track('metodo_pagamento', {
            clinic: this.clinic.name,
            payment_method: payment.payment_method.type,
            total: payment.amount
          });
        })
        this.clearForm();
        this.$bvModal.hide('bill-to-receive-product-modal')
        this.$bvModal.hide('warehouse-output-modal');
        this.clicked = "false";
      } catch(ex) {
        isLoading.hide();
        this.clicked = "false";
        this.$toast.error(ex.message);
      }
    },
    clearForm() {
        this.form = this.getDefaultForm();
        this.procedures =  {};
        this.validated = false;
        this.showFieldAppointment = false;
        this.showFieldsInstallment = false;
        this.showInfoAppointment = false;
        this.editMode = false;
        this.issueInvoice = false;
        this.blockEditHealthPlan = false;
        this.usePatientCredit = false
        this.creditAmount = 0
        this.patientCredit = null
        this.patientId = null
        this.creditPatientCategory = null
      },
    isValid() {
      this.validated = true;
      return this.form.category &&
        this.form.patient &&
        (this.form.final_amount || this.isPatientCreditCategory || this.canUsePatientCredit) &&
        this.form.service_date &&
        this.form.final_amount &&
        this.form.payments.every(payment =>
          payment.payment_method &&
          payment.bank_account &&
          payment.amount &&
          payment.due_date
        ) &&
        this.isValidSumAmount() && !this.isCreditBalanceNegative
    },
    isValidSumAmount() {
      let total = 0
      this.form.payments.filter(payment => payment.payment_method.name !== 'Crédito do paciente').forEach(payment => {
        total += payment.amount
      });
      if (this.isPatientCreditCategory) {
        return true
      }
      if (parseFloat(total.toFixed(2)) !== parseFloat(this.form.final_amount.toFixed(2))) {
        this.$toast.warning('A soma dos métodos de pagamento deve ser igual ao valor final')
        return false
      }
      return true
    },
    calculateFinalValue() {
      const amount = this.form.amount;
      const currency = this.form.currency;
      const discount = parseFloat(currency?.value === '%' ? this.form.discount.replace(',', '.') : this.form.discount);

      if (!amount || !discount || discount <= 0 || !currency || !currency.value) {
        this.form.final_amount = this.form.amount;
        return;
      }

      const realDiscount = currency.value === 'R$' ? discount : (amount * discount) / 100;

      this.form.final_amount = amount - realDiscount;
    },
    async getCreditPatient() {
      try {
        if (!this.canLoadCreditPatient) return;
        const response = await financialApi.getCreditPatient(this.clinic.id, this.form.patient.id, this.creditPatientCategory.id)

        if (response.data) {
          this.patientCredit = response.data
        }
      } catch (error) {
        this.$toast.error('Erro ao buscar crédito do paciente')
      }
    },
    async getPatientDepositCategory() {
      if (this.clinic?.id && this.showFeature('Caução do paciente')) {
        const response = await financialApi.getCreditPatientCategory(this.clinic.id)
        if (response) {
          this.creditPatientCategory = response.data
        }
      }
      this.getCreditPatient()
    },
    handleChangeCreditAmount(value) {
      this.form.discount = value
    },
    clearCreditForm() {
      this.usePatientCredit = false
      this.creditAmount = 0
      this.patientCredit = null
      this.patientId = null
    },
    handleUseTotalCredit(value) {
      if (value && this.patientCredit.credit <= (this.form.amount - this.getDiscount())) {
        this.creditAmount = this.patientCredit.credit
      } else if (value) {
        this.creditAmount = this.form.amount - this.getDiscount()
      }
    },

    handlePatientCreditPayment(toInsert) {
      if (toInsert) {
        this.$refs.payments.handleCreditPatientPayment(this.creditAmount)
      } else {
        this.$refs.payments.removeCreditPatientPayment()
      }
    },

    getFinalAmount() {
      if (!this.isPatientCreditCategory) return this.form.final_amount

      const amount = this.form.payments.reduce((acc, payment) => {
        acc += payment.amount
        return acc
      }, 0)

      return amount
    },
    getAmount() { 
      if (!this.isPatientCreditCategory) return this.form.amount

      const amount = this.form.payments.reduce((acc, payment) => {
        acc += payment.amount
        return acc
      }, 0)

      return amount
      
    },
    getDiscount() {     
      let discount = null

      if (this.form.currency?.value === '%') {
        discount = (this.form.discount / 100 * this.form.amount) + this.form.creditDiscount
      } else if (this.form.currency?.value === 'R$') {
        discount = this.form.discount + this.form.creditDiscount
      }

      return discount ? parseFloat(discount.toFixed(2)) : null
    },
    getCurrencyDiscount() {
      if (!this.creditAmount) return this.form.currency ? this.form.currency.value : null

      return 'R$'
    }

  },
  watch: {
    async billToReceive(value) {
      if (value) {
        this.form = {
          ...this.form,
          ...value,
        };
        const category = await this.getProductCategory();
        this.form = {
          ...this.form,
          category,
        };
        const amount = this.getTotalAmountSale();
        this.form.amount = amount;
        this.form.final_amount = amount;
        this.outputItem = value?.itemProps
        this.service_date = new Date(value.service_date)
      }
    },
    'form.amount': function(newAmount, oldAmount) {
      newAmount && newAmount !== oldAmount && this.calculateFinalValue();
    },
    'form.discount': function(newDiscount, oldDiscount) {
      newDiscount !== oldDiscount && this.calculateFinalValue();
    },
    'form.currency': function(newCurrency, oldCurrency) {
      newCurrency && newCurrency !== oldCurrency && newCurrency.value && this.calculateFinalValue();
    },
    creditAmount: function(value) {
      this.form.creditDiscount = value
      this.$refs.payments.setCrediPatientAmount(value)
    },
    'usePatientCredit': function(value) {
      if (value) {
        this.handlePatientCreditPayment(true)
      } else {
        this.form.creditDiscount = 0
        this.creditAmount = 0
        this.useCreditTotal = false
        this.handlePatientCreditPayment(false)
      }
    },
    useCreditTotal: function(value) {
      this.handleUseTotalCredit(value)
    },
    'form.patient': function (newPatient) {
      if(!newPatient){
        this.clearCreditForm()
      }
      else {
        this.patientId = newPatient.id
      }
    },
  }
}
</script>
<style lang="scss">
#bill-to-receive-product-modal {
  .modal-content {
    max-width: 750px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);

    .modal-body {
      padding: 0;

      .modal-header {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px;

        .modal-title {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: var(--type-active);
        }

        .icon {
          height: 24px;
          width: 24px;
          fill: var(--neutral-500);
        }
      }

      .credit-available {
        color: #069763;
        font-weight: bold;
      }

      .md-content {
        padding: 24px 24px 0 24px;

        .table {
          margin: 0 0 40px 0;

          td {
            border: none;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: var(--type-active);
          }

          .background {
            background-color: var(--neutral-100);
          }
          .head {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            color: var(--type-active);
          }
          .product {
            font-weight: 600;
            color: var(--neutral-700);
            border-top: 1px dashed var(--neutral-300);
          }
          .dashed {
            border-top: 1px dashed var(--neutral-300);
          }
          .total {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            color: var(--neutral-700);
          }
        }

        .action-button-wrapper {
          width: 100%;
          text-align: right;

          .action-button {
            margin: 20px 0;
          }
        }
      }
    }
  }
}
</style>
